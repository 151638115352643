// home

page.current = page.defaultPage.init(false);





/* Ajax Page */

page.ajax = new v.AjaxPage({
    console: false,
    timeoutAjax: 500,
    timeoutUpdate: 100,
    timeoutAfter: 50,
    on: v.desktop() || v.tablet() ? true : false
});
page.ajax = page.ajax.init();



// resize

v.addResize(['w', function(){
    if(v.desktop() || v.tablet()){
        page.ajax.changeProp({
            on: true
        });
    }
    else{
        if(page.ajax.changes() > 0){
            window.location.reload();
            return;
        }
        page.ajax.changeProp({
            on: false
        });
    }
}.bind(this)]);



// before

page.ajax.add({
    target: 'before',
    timeout: 50,
    do: function(){

        page.ajax.changeProp({
            disabled: true
        });

        page.screen.classList.add("hide");

        if(page.menu.shown()){
            page.menu.hide();
        }

        if(page.current.name() != ""){
            page[page.current.name()].destroy();
        }
        
    }.bind(this)
});



// load

page.ajax.add({
    target: 'load',
    timeout: 25,
    do: function(data){



        // get html

        let newHTML = document.createElement("html");
        newHTML.innerHTML = data.htmlFull;



        // set header active menu

        let menuLi = {
            new: newHTML.querySelectorAll(".v-menu__links li"),
            old: document.querySelectorAll(".v-menu__links li")
        },
            menuLiActive = -1;

        for(let i = 0; i < menuLi.new.length; i++){
            if(menuLi.new[i].classList.contains("active")){
                menuLiActive = i;   
            }
        }

        for(let i = 0; i < menuLi.old.length; i++){
            if(menuLiActive === i){
                menuLi.old[i].classList.add("active");
            }
            else{
                menuLi.old[i].classList.remove("active");
            }
        }



        // set header active languages

        let languagesUl = {
            new: newHTML.querySelector(".v-menu__languages"),
            old: document.querySelector(".v-menu__languages")
        };

        if(languagesUl.new !== null & languagesUl.old !== null){
            languagesUl.old.innerHTML = languagesUl.new.innerHTML;
        }



        // init new page

        if(data.next.includes("defaultPage")){
            page.current = page.defaultPage.init(true);
        }
        else{
            window.location.href = data.url;
        }



    }.bind(this)
});



// after

page.ajax.add({
    target: 'after',
    timeout: 25,
    do: function(data){

        page.screen.classList.remove("hide");

        setTimeout(() => {

            page.current.show(true);
            page.ajax.getElements();
            page.ajax.setEvents();

        }, 500);

        setTimeout(() => {

            page.ajax.changeProp({
                disabled: false
            });

        }, 1000);

    }.bind(this)
});